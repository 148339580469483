import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider/config/StateSchema';
import { userActions } from 'entities/user/model';

interface deleteAdditionalAddressResponse {
    result: boolean;
}
export const deleteAdditionalAddress = createAsyncThunk<
    number,
    number,
    ThunkConfig<string>
>('additionalAddress/deleteAdditionalAddress', async (
    addressId,
    { extra, dispatch, rejectWithValue },
) => {
    try {
        const response = await extra.api.delete<{data: deleteAdditionalAddressResponse}>(`/user/address/${addressId}`);

        if (!response.data.data.result) {
            return rejectWithValue('error');
        }

        return addressId;
    } catch (e) {
        return rejectWithValue('error');
    }
});
