import React, { HTMLAttributes, ReactNode } from 'react';
import { Typography } from '../Typography';
import { Card } from '../Card';
import cls from './CardWithIcon.module.scss';

interface CardWithIconProps extends HTMLAttributes<HTMLDivElement> {
  icon: ReactNode; // icon может быть изображением или любым React элементом
  title: string;
  text: string;
  className?: string;
  direction?: 'row' | 'column';
}

export const CardWithIcon: React.FC<CardWithIconProps> = ({
    className,
    icon,
    title,
    text,
    direction = 'row',
    ...props
}) => {
    const rootClasses = [cls.CardWithIcon, className].filter(Boolean).join(' ');
    const directionClass = cls[`direction_${direction}`]; // Для динамического направления

    return (
        <Card className={`${rootClasses} ${directionClass}`} {...props}>
            <div className={cls.flexBlock}>
                <div className={cls.flexBlock__Content}>
                    <Typography className={cls.title} variant="h2">
                        {title}
                    </Typography>
                    <Typography className={cls.text} variant="body">
                        {text}
                    </Typography>
                </div>
                <div className={cls.iconWrapper}>
                    {typeof icon === 'string' ? (
                        <img src={icon} alt={title} className={cls.icon} />
                    ) : (
                        icon
                    )}
                </div>
            </div>
        </Card>
    );
};
