import React, { useEffect, useRef } from 'react';
import {
    Box, Button, Link, TextField, Typography,
} from '@mui/material';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { supportNumberSelector } from 'entities/variables/model';
import { useAppDispatch, useAppSelector } from 'app/providers/StoreProvider';
import { getCodeByPhone, selectVerificationData } from 'features/Verification';

const schema = yup.object().shape({
    phone: yup
        .string()
        .required('Поле обязательно для ввода!')
        .min(16, 'Поле должно быть корректным номером телефона!'),
});

const PhoneConfirmForm = () => {
    const phoneInput = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });
    const { error: verifyError } = useAppSelector(selectVerificationData);
    const supportPhone = useSelector(supportNumberSelector);

    const onSubmit = (data: any) => {
        const phone = data.phone.replace(/[^[0-9]+/g, '').replace(/^7|^8/, '');

        dispatch(getCodeByPhone({ phone }));
        // @ts-ignore
        localStorage.setItem(
            'verification',
            JSON.stringify({
                tryDate: Date.now(),
                phone,
            }),
        );
    };

    useEffect(() => {
        phoneInput?.current?.focus();
    }, []);

    return (
        <>
            {!verifyError && (
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                        display: 'flex', flexDirection: 'column', gap: 10, maxWidth: '330px',
                    }}
                >
                    <Typography>
                        Укажите номер телефона для авторизации в личном кабинете.
                        В дальнейшем этот номер будет использоваться для получения
                        СМС-уведомлений.
                        {' '}
                    </Typography>
                    {/* @ts-ignore */}
                    <InputMask
                        mask="+7(999)999-99-99"
                        disabled={false}
                        maskChar=""
                        {...register('phone')}
                    >
                        {/* @ts-ignore */}
                        {() => (
                            <TextField
                                type="tel"
                                inputRef={phoneInput}
                                inputMode="tel"
                                label="Номер телефона"
                                name="phone"
                                error={!!errors.phone}
                                /* @ts-ignore */
                                helperText={errors?.phone?.message}
                            />
                        )}
                    </InputMask>
                    <Button
                        disabled={!isValid || !!verifyError}
                        variant="contained"
                        type="submit"
                    >
                        Получить код
                    </Button>
                </form>
            )}
            {verifyError === 'exceed' && (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body2" fontWeight="bold">
                        Попытки закончились, попробуйте позже...
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <img src="/images/cry.png" width={200} alt="" />
                    </Box>
                    <Typography color="gray" fontSize="small">
                        * или позвоните в
                        {' '}
                        <Link href={`tel:${supportPhone}`}>техподдержку</Link>
                    </Typography>
                </Box>
            )}
            {verifyError === 'unknown' && <div>Неизвестная ошибка</div>}
        </>
    );
};

export default PhoneConfirmForm;
