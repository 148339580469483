import React from 'react';
import { Container, Skeleton } from '@mui/material';
import { Button, Card, Flex } from 'shared/ui';
import useDeviceDetect from 'shared/hooks/useDeviceDetect/useDeviceDetect';
import { useSelector } from 'react-redux';
import { showMobileTariffsSelector } from 'entities/variables';
import cls from './HomePageLoader.module.css';

const HomePageLoader = () => {
    const { isMobile } = useDeviceDetect();
    const isShowMobileTariffs = useSelector(showMobileTariffsSelector);

    return (
        <Container disableGutters>
            <div className={cls.wrapper}>
                <div className={cls.contractCard}>
                    {!isMobile && <Skeleton width={200} height={140} />}
                    <Card>
                        <Flex direction="column" align="left" gap={5}>
                            {!isMobile && <Skeleton width={150} height={55} />}
                            <div className={cls.skeletonText}>
                                <Skeleton width={250} height={20} />
                                <Skeleton width={300} height={20} />
                                <Flex direction="row" align="center" justify="space-around" gap={20}>
                                    <Skeleton width={60} height={30} />
                                    <Skeleton width={60} height={30} />
                                </Flex>
                                {' '}
                                <Flex direction="row" align="center" justify="space-around" gap={20}>
                                    <Skeleton width={40} height={30} />
                                    <Skeleton width={40} height={30} />
                                </Flex>
                                <Skeleton width={150} height={20} />
                                <Flex direction="row" align="left" justify="flex-start" gap={20}>
                                    <Skeleton width={100} height={30} />
                                    <Skeleton width={80} height={30} />
                                </Flex>
                                <Flex justify="center">
                                    <Button variant="primary" className={cls.skeletButton}>
                                        <Skeleton width={200} height={40} />
                                    </Button>
                                </Flex>
                            </div>
                        </Flex>
                    </Card>
                    <Card className={cls.connectToInet}>
                        <Flex direction="column" align="flex-start" justify="center" gap={5}>
                            <Skeleton width={150} height={35} />
                            <Skeleton width={150} height={35} />
                        </Flex>
                    </Card>
                </div>
                <div className={cls.information}>
                    {!isMobile && (
                        <Container className={cls.container} sx={{ pb: 2 }}>
                            <Card>
                                <Flex direction="column" align="left" gap={20}>
                                    <Skeleton width="100%" height={200} />
                                </Flex>
                            </Card>
                        </Container>
                    )}

                    <div className={cls.container}>
                        {!isMobile && (
                            <Flex>
                                <Skeleton width={250} height={50} />
                            </Flex>
                        )}
                        <Flex gap={5} direction="column" justify="space-between">
                            <Card>
                                <Flex direction="row" align="left" gap={20}>
                                    <Skeleton width={100} height={70} />
                                    <div className={cls.skeletonText}>
                                        <Skeleton width={150} height={20} />
                                        <Skeleton width={200} height={27} />
                                        <Skeleton width="100%" height={19} />
                                    </div>
                                </Flex>
                            </Card>
                            {isShowMobileTariffs && (
                                <Card>
                                    <Flex direction="row" align="left" gap={20}>
                                        <Skeleton width={100} height={70} />
                                        <div className={cls.skeletonText}>
                                            <Skeleton width={150} height={20} />
                                            <Skeleton width={200} height={27} />
                                            <Skeleton width="100%" height={19} />
                                        </div>
                                    </Flex>
                                </Card>
                            )}
                            {!isMobile && (
                                <Flex>
                                    <Skeleton width={250} height={50} />
                                </Flex>
                            )}
                            <Card>
                                <Flex direction="row" align="left" gap={20}>
                                    <Skeleton width={100} height={70} />
                                    <div className={cls.skeletonText}>
                                        <Skeleton width={150} height={20} />
                                        <Skeleton width={200} height={27} />
                                        <Skeleton width="100%" height={19} />
                                    </div>
                                </Flex>
                            </Card>
                            <Card>
                                <Flex direction="row" align="left" gap={20}>
                                    <Skeleton width={100} height={70} />
                                    <div className={cls.skeletonText}>
                                        <Skeleton width={150} height={20} />
                                        <Skeleton width={200} height={27} />
                                        <Skeleton width="100%" height={19} />
                                    </div>
                                </Flex>
                            </Card>
                            <Card>
                                <Flex direction="row" align="left" gap={20}>
                                    <Skeleton width={100} height={70} />
                                    <div className={cls.skeletonText}>
                                        <Skeleton width={150} height={20} />
                                        <Skeleton width={200} height={27} />
                                        <Skeleton width="100%" height={19} />
                                    </div>
                                </Flex>
                            </Card>
                        </Flex>
                    </div>
                    <div className={cls.container}>
                        <Card>
                            <Flex direction="column" align="left" gap={25}>
                                <div className={cls.skeletonTextButton}>
                                    <Skeleton width={350} height={27} />
                                    <Skeleton width={300} height={19} />
                                </div>
                                <Flex justify="center">
                                    <Button variant="primary" className={cls.skeletButton}>
                                        <Skeleton width={100} height={40} />
                                    </Button>
                                </Flex>
                            </Flex>
                        </Card>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default HomePageLoader;
