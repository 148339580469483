import { FC, useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { PhoneAssociateInfo } from 'widgets/PhoneAssociateInfo';
import {
    Button, Card, Flex, Input, Typography,
} from 'shared/ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IProfile } from 'entities/user/model/types';
import { UserService } from 'shared/api';
import { InferType } from 'yup';
import {
    ProfileArrow,
    ProfileAttention, ProfileImg, ProfileMail, ProfileMonitor, ProfilePhone,
} from 'shared/assets/icons';
import { AdaptiveModal } from 'shared/ui/AdaptiveModal/AdaptiveModal';
import useDeviceDetect from 'shared/hooks/useDeviceDetect/useDeviceDetect';
import cls from './EditableProfileCard.module.scss';
import { profileSchema } from '../model/schema/profileSchema';

interface EditableProfileCardProps {
    className?: string;
    profile?: IProfile;
    isLoading: boolean;
}

type FormValues = InferType<typeof profileSchema>;
export const EditableProfileCard: FC<EditableProfileCardProps> = ({
    className,
    profile,
    isLoading,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitted },
    } = useForm<FormValues>({
        defaultValues: profile,
        mode: 'onBlur',
        resolver: yupResolver(profileSchema),
    });
    const needAssociate = profile?.needAssociatePhoneToServices;
    const [newEmail, setNewEmail] = useState(false);
    const [profileEmail, setProfileEmail] = useState(profile?.email);
    const { isMobile } = useDeviceDetect();
    const onSaveClick = async (data: FormValues) => {
        const res = await UserService.edit({
            email: data.email,
        });
        setProfileEmail(res.data.data.email);
        setNewEmail(false);
    };
    useEffect(() => setProfileEmail(profile?.email), [profile]);

    if (isLoading) {
        return (
            <Card className={className}>
                <div className={cls.Form}>
                    <Flex align="center" gap={20}>
                        <Skeleton sx={{ height: '70px', minWidth: '70px' }} variant="circular" />
                        <Skeleton height={50} width="100%" variant="rounded" />
                    </Flex>
                    <br />
                    <Flex direction="column" gap={10}>
                        <Skeleton height={70} width="100%" variant="rounded" />
                        <Skeleton height={70} width="100%" variant="rounded" />
                    </Flex>
                </div>
            </Card>
        );
    }

    return (
        <Card className={className}>
            <div className={cls.nameField}>
                <ProfileImg className={cls.profileImg} />
                {profile && `${profile?.firstName} ${profile?.middleName} ${profile?.lastName}`}
            </div>
            <div className={cls.Form}>
                <div className={cls.inputContainer}>
                    <div className={cls.inputIcon}>
                        <ProfileMail />
                    </div>
                    <Flex direction="column">
                        <div className={cls.emailTitle}>
                            Email
                            {!profile?.email && <ProfileAttention />}
                        </div>
                        <div className={cls.inputField}>{profileEmail || 'Email не указан'}</div>
                    </Flex>
                    {isMobile ? (
                        <Button className={cls.addEmailBtn_mobile} onClick={() => setNewEmail(true)}>
                            <ProfileArrow />
                        </Button>
                    ) : (
                        <Button className={cls.addEmailBtn} onClick={() => setNewEmail(true)}>
                            {profile?.email ? 'Изменить Email' : 'Добавить Email'}
                        </Button>
                    )}
                </div>
                <div className={cls.lastStr}>
                    <div className={cls.inputIcon}>
                        <ProfilePhone />
                    </div>
                    {needAssociate ? (
                        <Flex direction="row" justify="space-between" align="center">
                            <Flex direction="column">
                                <Flex className={cls.emailTitle}>
                                    Телефон
                                    {needAssociate && <ProfileAttention />}
                                </Flex>
                                <div className={cls.inputField}>
                                    {profile?.phone || 'Номер телефона не указан'}
                                </div>
                            </Flex>
                            <PhoneAssociateInfo profilePage />
                        </Flex>
                    ) : (
                        <Flex direction="column">
                            <Flex>
                                <div className={cls.emailTitle}>Телефон</div>
                                {needAssociate && <ProfileAttention />}
                            </Flex>
                            <div className={cls.inputField}>{`+7${profile?.phone}`}</div>
                        </Flex>
                    )}
                </div>
                <AdaptiveModal
                    initialFocusRef={false}
                    open={newEmail}
                    onClose={() => {
                        setNewEmail(false);
                    }}
                >
                    <form onSubmit={handleSubmit(onSaveClick)}>
                        <Typography gutterBottom variant="body">
                            Привяжите электронную почту для отправки чеков и документов.
                        </Typography>
                        <Input
                            fullwidth
                            placeholder="E-mail"
                            variant="outlined"
                            {...register('email')}
                            defaultValue={profile?.email}
                        />
                        {isSubmitted && errors.email && (
                            <span className={cls.errorText}>{errors.email.message}</span>
                        )}
                        <div className={cls.emailBtns}>
                            <Button className={cls.editBtn} type="submit">
                                Сохранить
                            </Button>
                        </div>
                    </form>
                </AdaptiveModal>
                {/* <div className={cls.inputContainer}>
                    <div className={cls.inputIcon}>
                        <ProfileMonitor />
                    </div>
                    <Flex direction="column">
                        <div className={cls.emailTitle}>Устройства и доступ</div>
                    </Flex>
                </div>
                <div className={cls.lastStr}>
                    <div className={cls.inputIcon}>
                        <ProfileMessange />
                    </div>
                    <Flex direction="column">
                        <div className={cls.emailTitle}>Связь с Управляющей компанией</div>
                    </Flex>
                </div> */}
            </div>
        </Card>
    );
};
