import {
    FC, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
    Button, Card, Flex, Typography,
} from 'shared/ui';
import {
    TextField,
} from '@mui/material';
import { IContract, InetSet } from 'entities/user/model/types';
import { IContractPart, currentContractSelector, profileSelector } from 'entities/user/model';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { payForwardSelector } from 'entities/variables';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'shared/lib';
import SetPaymentButton from './SetPaymentButton';
import { searchAddressByUid } from '../model/services/searchAddressByUid';
import cls from './PaymentForm.module.scss';
import 'react-toastify/dist/ReactToastify.css';

const schema = yup
    .object()
    .shape({
        payment: yup
            .number()
            .positive()
            .integer()
            .required()
            .max(15000, 'Не больше 15'),
        email: yup.string().required().email(),
        address_full: yup.string().required(),
        uid: yup.number().positive().integer(),
    })
    .required();
type FormData = yup.InferType<typeof schema>;

interface PaymentFormProps {
  className?: string;
  contract: IContract;
  onSubmit: (data: any) => void;
  isActive: boolean; // Проп для определения активной карточки
  onIsActiveChange: () => void; // Проп для изменения isActive
  onFormDataChange: (data: FormData) => void; // Проп для передачи данных формы на уровень выше
}

export const PaymentForm: FC<PaymentFormProps> = ({
    contract, isActive, onIsActiveChange, className = '', onFormDataChange,
}) => {
    const navigate = useNavigate();
    const profile = useSelector(profileSelector);
    const [periodPayment, setPeriodPayment] = useState<number>(0);
    const [uid, setUid] = useState<number>(contract.uid || 0);
    const payForward = useSelector(payForwardSelector(contract.price));
    const [selectedContracts, setSelectedContracts] = useState<IContract[]>([]);
    const [sumPayment, setSumPayment] = useState<number>(contract.recommendedPayment);
    const currentContract = useSelector(currentContractSelector);
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            payment: sumPayment || '',
            email: profile?.email,
            address_full: contract.addressFull,
            uid: contract.uid || '',
            oper: contract.oper || '',
        },
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (isActive) {
            setValue('payment', sumPayment);
            setValue('uid', currentContract?.uid);
            setValue('address_full', currentContract?.addressFull);
            setValue('oper', currentContract?.oper);
        }
    }, [currentContract, isActive, contract, sumPayment]);

    useEffect(() => {
        setSelectedContracts([]);
    }, []);

    useEffect(() => {
        const addresses = selectedContracts?.map((contract: IContract | IContractPart) => {
            if (contract && 'operTitle' in contract) {
                return {
                    label: contract.address,
                    value: contract.address,
                };
            }
            if (contract && typeof contract.address === 'object') {
                const addressFull = `${contract.address.city}, ${contract.address.street} ${
                    contract.address.home
                }, кв.${contract.address.flat}`;
                return {
                    label: addressFull,
                    value: addressFull,
                };
            }

            return {};
        });
        if (addresses && addresses.length === 1) {
            setValue('address_full', addresses[0].value);
        }
    }, [selectedContracts]);

    const onSubmit = async (data: FormData) => {
        const addressData = {
            choosenAddress: currentContract?.addressFull,
            uid: currentContract?.uid,
            oper: currentContract?.oper,
        };
        if (currentContract?.addressFull) { localStorage.setItem('choosenAddress', JSON.stringify(addressData)); }
        navigate('/pay');
        localStorage.setItem('paymentData', JSON.stringify(data));
    };

    useEffect(() => {
        if (isActive) {
            const subscription = watch((data) => {
                onFormDataChange(data as FormData);
            });
            return () => subscription.unsubscribe();
        }
    }, [watch, isActive]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card
                className={`${cls.payForm} ${isActive ? cls.payFormActive : ''}`}
                onClick={onIsActiveChange}
            >
                <div
                    className={`${cls.contractAddress} ${isActive ? cls.contractAddressActive : ''}`}
                >
                    <div className={`${cls.circle} ${!isActive && cls.circleActive}`}>
                        <div className={`${cls.circleInCircle} ${!isActive && cls.circleInCircleActive}`} />
                    </div>
                    <Flex direction="column" gap={4}>
                        <div className={cls.dogovor}>
                            Договор №
                            {contract.uid}
                        </div>
                        <div className={cls.addressFull}>
                            {contract.addressFull}
                        </div>
                    </Flex>
                </div>
                {isActive && (
                    <>
                        <div className={cls.tariffName}>
                            <Typography variant="body">
                                Название тарифа/пакета/услуги
                            </Typography>
                        </div>
                        <div className={cls.emailPay}>
                            <TextField
                                id="email"
                                margin="dense"
                                placeholder="Email для отправки чека"
                                label="Email для отправки чека"
                                variant="outlined"
                                type="email"
                                defaultValue={profile?.email || ''}
                                {...register('email')}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '48px',
                                        '& input': {
                                            height: '100%',
                                            boxSizing: 'border-box',
                                        },
                                        '& fieldset': {
                                            borderRadius: '8px',
                                            borderColor: `${isActive ? '#49A0B4' : '#D8D8D8'}`,
                                        },
                                        '&:hover fieldset': {
                                            borderColor: `${isActive ? '#49A0B4' : '#D8D8D8'}`,
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: `${isActive ? '#49A0B4' : '#D8D8D8'}`,
                                        },
                                    },
                                }}
                            />
                            <TextField
                                id="payment"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">₽</InputAdornment>,
                                    inputProps: { min: 1, max: 15000 },
                                }}
                                InputLabelProps={{ shrink: true }}
                                margin="dense"
                                label="Итого к оплате"
                                variant="outlined"
                                type="number"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '48px',
                                        '& input': {
                                            height: '100%',
                                            boxSizing: 'border-box',
                                        },
                                        '& fieldset': {
                                            borderRadius: '8px',
                                            borderColor: `${isActive ? '#49A0B4' : '#D8D8D8'}`,
                                        },
                                        '&:hover fieldset': {
                                            borderColor: `${isActive ? '#49A0B4' : '#D8D8D8'}`,
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: `${isActive ? '#49A0B4' : '#D8D8D8'}`,
                                        },
                                    },
                                }}
                                onInput={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    if (input.value) {
                                        let value = parseInt(input.value, 10);
                                        if (value > 15000) {
                                            value = 15000;
                                        } else if (value < 1) {
                                            value = 1;
                                        }
                                        input.value = value.toString(); // Ограничиваем значение от 1 до 15000
                                    }
                                }}
                                {...register('payment')}
                            />

                        </div>
                        {contract && (
                            <div className={cls.bonuses}>
                                {payForward
                                && payForward.startTarifPrice !== 0
                                && Object.entries(payForward.bonusPayout).map(([key, value]) => (
                                    <SetPaymentButton
                                        number={value}
                                        key={value}
                                        unit="баллов"
                                        description={(key === '6' || key === '12')
                                            ? (`за ${key} месяцев`)
                                            : (`за ${key} месяца`)}
                                        className={classNames(
                                            cls.paymentButtonBonus,
                                            { [cls.active]: Number(key) === periodPayment },
                                        )}
                                        callback={() => {
                                            setPeriodPayment(Number(key));
                                            setSumPayment(+key * contract.price);
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                        <div className={cls.wrapperButton}>
                            <Button
                                className={cls.paymentButton}
                                fullwidth
                                type="submit"
                            >
                                Далее
                            </Button>
                        </div>
                    </>
                )}
            </Card>
        </form>
    );
};
