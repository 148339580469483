import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
} from '@mui/material';
import { Button, Flex, Modal } from 'shared/ui';
import { CodeConfirmForm } from 'features/Verification/ui/CodeConfirmForm';
import { fetchUserInfo } from 'entities/user/model/services';
import { UserService } from 'shared/api';
import { useAppDispatch, useAppSelector } from 'app/providers/StoreProvider';
import { selectVerificationData } from 'features/Verification';
import { PhoneConfirmForm } from 'widgets/PhoneConfirmForm';
import { Arrow } from 'shared/assets/icons';
import useDeviceDetect from 'shared/hooks/useDeviceDetect/useDeviceDetect';
import cls from './PhoneAssociateInfo.module.scss';

interface PhoneAssociateInfoProps{
    profilePage?:boolean;
}

const PhoneAssociateInfo: React.FC<PhoneAssociateInfoProps> = ({ profilePage }) => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const { step, phone } = useAppSelector(selectVerificationData);
    const { isMobile } = useDeviceDetect();

    useEffect(() => {
        if (step === 'success') {
            (async () => {
                await UserService.edit({ phone });
                dispatch(fetchUserInfo());
            })();
        }
    }, [step]);

    return (
        <>
            {profilePage && (
                <Button
                    onClick={() => setOpen(true)}
                    className={cls.associateButton}
                >
                    Привязать номер
                </Button>
            )}
            <div className={cls.noPhoneAlert}>
                <div
                    className={cls.alertTitle}
                    onClick={() => setOpen(true)}
                >
                    <Typography variant="body2">
                        Необходимо привязать номер телефона к аккаунту
                    </Typography>
                    <Button
                        variant="clear"
                    >
                        <Arrow className={cls.arrowDirection} />
                    </Button>
                </div>

                <Modal
                    isOpen={open}
                    onClose={() => setOpen(false)}
                >
                    <Flex direction="column" className={cls.adaptFlex}>
                        {step === 'phone' && <PhoneConfirmForm />}
                        {step === 'code' && <CodeConfirmForm />}
                        {step === 'success' && (
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant="body2" fontWeight="bold">
                                    Номер телефона успешно привязан!
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <img src="/images/love.png" width={200} alt="" />
                                </Box>
                            </Box>
                        )}
                    </Flex>
                </Modal>

            </div>
        </>
    );
};

export default PhoneAssociateInfo;
