import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { authActions } from 'features/Auth/model';
import { useNavigate } from 'react-router-dom';

const fpPromise = FingerprintJS.load();
export const API_URL = process.env.API_URL || 'https://api.baza.net';
export const API_BAZA = process.env.API_BAZA || 'https://api.baza.net';

// @ts-ignore
let store:any;

export function getFinger() {
    if (localStorage.getItem('fingerprint')) {
        return localStorage.getItem('fingerprint');
    }
    fpPromise.then((fingerprint) => {
        fingerprint.get().then((result) => {
            localStorage.setItem('fingerprint', result.visitorId);
        });
    });
    return localStorage.getItem('fingerprint');
}

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});

// @ts-ignore
export const injectStore = (_store: any) => {
    store = _store;
};
$api.interceptors.request.use((config) => {
    if (config) {
    // @ts-ignore
        config.headers.authorization = store.getState().auth.token;
    }

    return config;
});

const refreshAuthLogic = (failedRequest: any) => axios
    .create({
        withCredentials: true,
        baseURL: API_BAZA,
    })
    .post('/auth/refresh_token', {
        fingerprint: getFinger(),
    })
    .then((tokenRefreshResponse) => {
        // refresh success logic
        // @ts-ignore
        store.dispatch(
            authActions.setAccessToken(tokenRefreshResponse.data.accessToken),
        );
        localStorage.setItem('accToken', tokenRefreshResponse.data.accessToken);
        // @ts-ignore
        store.dispatch(authActions.setAuth(true));

        return Promise.resolve();
    })
    .catch((e) => {
        // refresh failed logic
        store.dispatch(
            authActions.setAccessToken(''),
        );
        localStorage.setItem('isLogin', '0');
        localStorage.removeItem('accToken');
        window.location.href = '/login';
        if (e.request.responseURL === `${API_URL}/auth/refresh_token`) {
            return Promise.reject();
        }
    });

createAuthRefreshInterceptor($api, refreshAuthLogic);

export default $api;
