import {
    FC,
    MouseEvent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Portal } from 'shared/ui/Portal';
import cls from './Modal.module.scss';
import { classNames } from '../../../lib';

interface ModalProps {
  className?: string;
  children?: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  lazy?: boolean;
  scrollOn?: boolean;
}

const ANIMATION_DELAY = 300;
export const Modal: FC<ModalProps> = ({
    className,
    children,
    onClose,
    isOpen,
    lazy,
    scrollOn = false,
}) => {
    const [isClosing, setIsClosing] = useState(false);
    const [isOpening, setIsOpening] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const timerRefClosing = useRef<ReturnType<typeof setTimeout>>();
    const timerRefOpening = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        if (isOpen) {
            setIsOpening(true);
            setIsMounted(true);
            timerRefOpening.current = setTimeout(() => {
                setIsOpening(false);
            }, ANIMATION_DELAY);
        }

        // @ts-ignore
        return () => clearTimeout(timerRefOpening);
    }, [isOpen]);

    const closeHandler = useCallback(() => {
        if (onClose) {
            setIsClosing(true);
            timerRefClosing.current = setTimeout(() => {
                onClose();
                setIsMounted(false);
                setIsClosing(false);
            }, ANIMATION_DELAY);
        }
    }, [onClose]);

    const onContentClick = (e: MouseEvent) => {
        e.stopPropagation();
    };

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                closeHandler();
            }
        },
        [closeHandler],
    );

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('keydown', onKeyDown);
        }

        return () => {
            clearTimeout(timerRefClosing.current);
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [isOpen, onKeyDown]);

    const mods: any = {
        [cls.opened]: isOpen,
        [cls.isClosing]: isClosing,
        [cls.isOpening]: isOpening,
    };

    if (lazy && !isMounted) {
        return null;
    }

    // добавляем классы для возможности скролла модалки
    let clsOverlay = cls.overlay;
    let clsContent = cls.content;
    if (scrollOn) {
        clsOverlay = classNames(cls.overlay, mods, [cls.hiddenScroll]);
        clsContent = classNames(cls.content, mods, [cls.marginTop]);
    }

    return (
        <Portal>
            <div className={classNames(cls.Modal, mods, [className])}>
                <div className={clsOverlay} onClick={closeHandler}>
                    <div className={clsContent} onClick={onContentClick}>
                        {children}
                    </div>
                </div>
            </div>
        </Portal>
    );
};
