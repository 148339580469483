import React, { ReactNode, Ref } from 'react';
import useDeviceDetect from 'shared/hooks/useDeviceDetect/useDeviceDetect';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { snapPoints } from 'react-spring-bottom-sheet/dist/types';
import { Modal } from 'shared/ui/Modal';
import cls from './AdaptiveModal.module.scss';

interface AdaptiveModalProps {
    className?: string;
    onClose?: () => void;
    open: boolean;
    ref?: Ref<BottomSheetRef>;
    // snapPoints?: number[] | number;
    snapPoints?: snapPoints;
    scrollLocking?: boolean;
    initialFocusRef?: any;
    children: ReactNode;
    scrollOn?: boolean;
}

export const AdaptiveModal: React.FC<AdaptiveModalProps> = (
    {
        className, onClose, ref, initialFocusRef, scrollLocking = false,
        snapPoints, open, children, scrollOn, ...props
    },
) => {
    const { isMobile } = useDeviceDetect();

    const handleBottomSheetClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    return isMobile ? (
        <BottomSheet
            initialFocusRef={initialFocusRef}
            className={className}
            snapPoints={snapPoints}
            scrollLocking={scrollLocking}
            open={open}
            ref={ref}
            onDismiss={onClose}
            onClick={handleBottomSheetClick}
        >
            <div className={cls.bottomSheetWrapper}>
                {children}
            </div>
        </BottomSheet>
    ) : (
        <Modal
            lazy
            isOpen={open}
            className={className}
            onClose={onClose}
            scrollOn={scrollOn}
        >
            <div className={cls.wrapper}>
                {children}
            </div>
        </Modal>
    );
};
